export default {
  state: {
    offres: [],
  },
  mutations: {
    SET_ALL_OFFRE: (state, payload) => {
      state.offres = payload;
    },
    ADD_OFFRE: (state, payload) => {
      state.offres.unshift(payload);
    },
  },
  actions: {
    setAllOffre: (store, data) => {
      store.commit("SET_ALL_OFFRE", data);
    },
    addOffre: (store, data) => {
      store.commit("ADD_OFFRE", data);
    },
  },
  getters: {
    allOffre: (state) => state.offres,
    getOffreById: (state) => (id) => {
      return state.offres.find((offre) => offre.id_offre === id);
    },
  },
  namespaced: true,
  modules: {},
};
