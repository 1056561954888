import Vuex from "vuex";
import Vue from "vue";
import visiteurStore from "./visiteurStore";
import collaborateurStore from "./collaborateurStore";
import articleStore from "./articleStore";
import offreStore from "./offreStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    visiteurStore,
    collaborateurStore,
    articleStore,
    offreStore,
  },
});
