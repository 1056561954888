export const routes = [
  {
    path: "/",
    name: "Accueil",
    meta: {
      title: "MK-CO",
    },
    component: () => import("../views/visiteur/Accueil.vue"),
  },
  {
    path: "/se-connecter",
    name: "Login",
    component: () => import("../views/visiteur/Login.vue"),
    meta: {
      title: "Connexion",
      noAccessTo: true,
    },
  },
  {
    path: "/mon-espace",
    name: "Myspace",
    component: () => import("../views/visiteur/ProfilVisiteur.vue"),
    meta: {
      title: "Mon espace",
      noAccessNotLoggedInVisiteur: true,
    },
  },
  {
    path: "/mdp-oublie",
    name: "MdpForget",
    component: () => import("../views/visiteur/MdpForget.vue"),
    meta: {
      title: "Mot de passe oublié",
      noAccessTo: true,
    },
  },
  {
    path: "/je-me-desabonne/:token",
    name: "SeDesabonne",
    component: () => import("../views/visiteur/SeDesabonne.vue"),
    meta: {
      title: "Je me désabonne",
    },
  },
  {
    path: "/accompagnement/:id_services",
    name: "ServiceVisiteur",
    meta: {
      title: "Accompagnement",
    },
    component: () => import("../views/visiteur/Service.vue"),
  },
  {
    path: "/offre-emploi",
    name: "OffreEmploiVisiteur",
    meta: {
      title: "Offres d'emploi",
    },
    component: () => import("../views/visiteur/OffreEmploi.vue"),
  },
  {
    path: "/articles",
    name: "ArticleVisiteur",
    meta: {
      title: "Articles",
    },
    component: () => import("../views/visiteur/Article.vue"),
  },
  {
    path: "/select-article/:slug",
    name: "ArticleSelected",
    meta: {
      title: "Article",
    },
    component: () => import("../views/visiteur/SelectArticle.vue"),
  },
  {
    path: "/admin/se-connecter-admin",
    name: "LoginAdmin",
    component: () => import("../views/admin/Login.vue"),
    meta: {
      title: "Connexion Admin",
      noAccesOnLogged: true,
    },
  },
  {
    path: "/forget-password/:token",
    name: "ForgetPasswordForm",
    component: () => import("../views/visiteur/ResetPasswordForm.vue"),
  },
  // {
  //   path: "/erreur-mail",
  //   name: "page-erreur-creation-mail",
  //   component: () => import("../views/visiteur/page-erreur-creation-mail.vue"),
  // },
  /*{
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("../views/visiteur/ForgetPassword.vue"),
  },
  {
    path: "/forget-password/:token",
    name: "ForgetPasswordForm",
    component: () => import("../views/visiteur/ResetPasswordForm.vue"),
  },

  {
    path: "*",
    name: "notFound",
    component: () => import("../views/visiteur/notFound.vue"),
  },*/
  {
    path: "/admin",
    component: () => import("../layout/LayoutAdmin.vue"),
    meta: {
      noAccessNotLoggedIn: true,
    },
    children: [
      {
        path: "me",
        name: "Me",
        component: () => import("../views/admin/Me.vue"),
        meta: {
          title: "Moi",
          noAccessNotLoggedIn: true,
          needSuperAdmin: true,
        },
      },
      {
        path: "dashboard",
        name: "DashboardAdmin",

        component: () => import("../views/admin/Dashboard.vue"),
        meta: {
          title: "Tableau de bord MK-CO",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "nouvel-article",
        name: "ArticleNouveau",

        component: () => import("../views/admin/ArticleNouveau.vue"),
        meta: {
          title: "Nouvel article",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-article",
        name: "ArticleListe",

        component: () => import("../views/admin/ArticleListe.vue"),
        meta: {
          title: "Liste articles",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "commentaire-article",
        name: "CommentaireArticle",

        component: () => import("../views/admin/CommentaireAdmin.vue"),
        meta: {
          title: "Commentaires de l'article",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "nouvelle-offre",
        name: "OffreNouveau",

        component: () => import("../views/admin/OffreNouveau.vue"),
        meta: {
          title: "Nouvelle offre d'emploi",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-offre",
        name: "OffreListe",

        component: () => import("../views/admin/OffreListe.vue"),
        meta: {
          title: "Liste offres d'emplois",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-newsletter",
        name: "NewsletterListe",

        component: () => import("../views/admin/NewsletterListe.vue"),
        meta: {
          title: "Liste newsletters",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-partenaire",
        name: "PartenaireListe",

        component: () => import("../views/admin/PartenaireListe.vue"),
        meta: {
          title: "Liste des partenaires",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-reference",
        name: "ReferenceListe",

        component: () => import("../views/admin/ReferenceListe.vue"),
        meta: {
          title: "Liste des références",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "accompagnement",
        name: "Accompagnement",

        component: () => import("../views/admin/Accompagnement.vue"),
        meta: {
          title: "Accompagnement",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "liste-particularite",
        name: "ParticulariteListe",

        component: () => import("../views/admin/ParticulariteListe.vue"),
        meta: {
          title: "Liste particularités",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "candidat-service",
        name: "CandidatService",
        component: () => import("../views/admin/CandidatService.vue"),
        meta: {
          title: "Candidat Service",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "candidat-mk",
        name: "CandidatMk",
        component: () => import("../views/admin/CandidatMk.vue"),
        meta: {
          title: "Candidat Service",
          noAccessNotLoggedIn: true,
          needSuperAdmin: true,
        },
      },
      // possible à supprimer
      {
        path: "liste-candidat",
        name: "CandidatListe",

        component: () => import("../views/admin/CandidatListe.vue"),
        meta: {
          title: "Liste des postulants",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre/candidat/:id",
        name: "candidat",
        component: () => import("../views/admin/CandidatOffre.vue"),
        meta: {
          title: "Liste des postulants",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "collaborateur",
        name: "collaborateurListe",
        component: () => import("../views/admin/Collaborateur.vue"),
        meta: {
          title: "Liste des collaborateurs",
          noAccessNotLoggedIn: true,
          needSuperAdmin: true,
        },
      },
      {
        path: "type-offre",
        name: "TypeOffre",
        component: () => import("../views/admin/TypeOffre.vue"),
        meta: {
          title: "Type",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "categorie-offre",
        name: "CategorieOffre",
        component: () => import("../views/admin/CategorieOffre.vue"),
        meta: {
          title: "Catégorie",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "categorie-article",
        name: "CategorieArticle",
        component: () => import("../views/admin/CategorieArticle.vue"),
        meta: {
          title: "Catégorie",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre/:id",
        name: "OffreSelected",
        component: () => import("../views/admin/SelectOffre.vue"),
        meta: {
          title: "Offre",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article/commentaire/:id",
        name: "Commentaire",
        component: () => import("../views/admin/CommentaireAdmin.vue"),
        meta: {
          title: "Commentaire",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "question",
        name: "Question",
        component: () => import("../views/admin/Question.vue"),
        meta: {
          title: "Question",
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article/:id",
        name: "ArticleSelectedAdmin",
        component: () => import("../views/admin/SelectArticle.vue"),
        meta: {
          title: "Article",
          noAccessNotLoggedIn: true,
        },
      },
      /*{
        path: "demandeClient",
        name: "DemandeClient",
        component: () => import("../views/admin/DemandeClient.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "newsLetter",
        name: "NewsLetter",
        component: () => import("../views/admin/NewsLetter.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offreMK-CO",
        name: "OffreMK-CO",
        component: () => import("../views/admin/OffreMK-CO.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article",
        component: () =>
          import("../components/admin/article/ListArticleAdmin.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article/newArticle",
        name: "newArticle",
        component: () =>
          import("../components/admin/article/FormNewArticle.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article/commentaire/:id",
        name: "commentaire",
        component: () => import("../components/admin/article/Commentaire.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "article/:id",
        name: "selectArticle",
        component: () =>
          import("../components/admin/article/SelectArticle.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre",
        name: "Offre",
        component: () => import("../components/admin/offre/ListOffreAdmin.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre/newOffre",
        name: "newOffre",
        component: () => import("../components/admin/offre/FormNewOffre.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre/candidat/:id",
        name: "candidat",
        component: () => import("../components/admin/offre/Candidat.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },
      {
        path: "offre/:id",
        name: "OffreSelected",
        component: () => import("../components/admin/offre/SelectOffre.vue"),
        meta: {
          noAccessNotLoggedIn: true,
        },
      },*/
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("../views/visiteur/NotFound.vue"),
    meta: {
      title: "Page introuvable",
    },
  },
];
