export default {
  state: {
    articles: [],
    selectedArticle: {},
  },
  mutations: {
    SET_ALL_ARTICLE: (state, payload) => {
      state.articles = payload;
    },
    SET_SELECTED_ARTICLE: (state, payload) => {
      state.selectedArticle = payload;
    },
    ADD_ARTICLE: (state, payload) => {
      state.articles.unshift(payload);
    },
    ADD_COMMENT: (state, payload) => {
      state.selectedArticle.commentaires.unshift(payload);
    },
  },
  actions: {
    setAllArticle: (store, data) => {
      store.commit("SET_ALL_ARTICLE", data);
    },
    setSelectedArticle: (store, data) => {
      store.commit("SET_SELECTED_ARTICLE", data);
    },
    addArticle: (store, data) => {
      store.commit("ADD_ARTICLE", data);
    },
    addComment: (store, data) => {
      store.commit("ADD_COMMENT", data);
    },
  },
  getters: {
    allArticle: (state) => state.articles,
    selectedArticle: (state) => state.selectedArticle,
    getArticleById: (state) => (id) => {
      return state.articles.find((a) => a.id_article === id);
    },
  },
  namespaced: true,
  modules: {},
};
