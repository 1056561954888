<template>
  <!-- modal add début-->

  <div class="modal-dialog cookie">
    <div class="d-flex cookie-content">
      <img src="../../assets/img/cookie.png" alt="..." />
      <div class="content">
        <h3 class="fw-bolder">We use cookie</h3>
        <div>
          lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
        </div>
        <div class="d-flex mt-2">
          <button class="btn btn-outline-dark me-3">Rejeter</button>
          <button class="btn btn-primary">Accepter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCookie",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cookie-content {
  height: 100%;
}
img {
  margin: auto;
  width: 25%;
}
.content {
  margin: auto;
  width: 50%;
}
.cookie {
  background-color: white;
  position: fixed;
  bottom: 0;
  height: 30%;
  margin: 20px;
  border-radius: 10px;
  align-items: center;
  max-width: 100%;
}
@media (min-width: 768px) {
  .cookie {
    background-color: white;
    position: fixed;
    bottom: 0;
    height: 30%;
    margin: 20px;
    border-radius: 10px;
    align-items: center;
    max-width: 47%;
  }
}
</style>
