import { io } from "socket.io-client";

export const socket = io("https://www.mk-co.org", {
  path: "/socket/",
});
// deploy fin
// import socketClient from "socket.io-client";
// import { BASE_URL } from "../configs/index";

// export const socket = socketClient(BASE_URL);
//export const socket = io("https://localhost:3000");

// export const socket = io("http://localhost:4000", {
//   path: "/socket/",
// });
