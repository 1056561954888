export default {
  state: {
    collaborateur: {},
    isLoggedInCollaborateur: false,
  },
  mutations: {
    SET_COLLABORATEUR: (state, payload) => {
      state.collaborateur = payload;
    },
    SET_CONNECTED: (state) => {
      state.isLoggedInCollaborateur = true;
    },
    SET_DISCONNECTED: (state) => {
      state.isLoggedInCollaborateur = false;
    },
  },
  actions: {
    setCollaborateur: (store, data) => {
      store.commit("SET_COLLABORATEUR", data);
    },
    setConnected: (store) => {
      store.commit("SET_CONNECTED");
    },
    setDisconnected: (store) => {
      store.commit("SET_DISCONNECTED");
    },
  },
  getters: {
    isLoggedInCollaborateur: (state) => state.isLoggedInCollaborateur,
    meCollab: (state) => state.collaborateur,
  },
  namespaced: true,
  modules: {},
};
