import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "../store/index";
import { decodeToken } from "../utils/decodeToken";

Vue.use(VueRouter);

try {
  const decode = decodeToken(localStorage.getItem("tokenCollab"));
  if (decode) {
    //console.log("ok collab");
    store.dispatch("collaborateurStore/setCollaborateur", decode);
    store.dispatch("collaborateurStore/setConnected");
  }
} catch (err) {
  //console.log(err);
}

try {
  const decodeV = decodeToken(localStorage.getItem("token"));
  if (decodeV) {
    //console.log("ok visiteur");
    store.dispatch("visiteurStore/setVisiteur", decodeV);
    store.dispatch("visiteurStore/setConnected");
  }
} catch (err) {
  //console.log(err);
}

// const routes = [
//   {
//     path: "/",
//     name: "Accueil",
//     meta: {
//       title: "MK-CO",
//     },
//     component: () => import("../views/visiteur/Accueil.vue"),
//   },
//   {
//     path: "/se-connecter",
//     name: "LoginVisiteur",
//     meta: {
//       title: "Connexion",
//       noAccessTo: true,
//     },
//     component: () => import("../views/visiteur/Login.vue"),
//   },
//   {
//     path: "/services",
//     name: "ServiceVisiteur",
//     meta: {
//       title: "Services",
//     },
//     component: () => import("../views/visiteur/Service.vue"),
//   },
//   {
//     path: "/offre-emploi",
//     name: "OffreEmploiVisiteur",
//     meta: {
//       title: "Offres d'emploi",
//     },
//     component: () => import("../views/visiteur/OffreEmploi.vue"),
//   },
//   {
//     path: "/articles",
//     name: "ArticleVisiteur",
//     meta: {
//       title: "Articles",
//     },
//     component: () => import("../views/visiteur/Article.vue"),
//   },
//   {
//     path: "/se-connecter-admin",
//     name: "LoginAdmin",
//     meta: {
//       title: "Connexion Admin",
//     },
//     component: () => import("../views/admin/Login.vue"),
//   },
//   {
//     path: "/dashboard",
//     name: "DashboardAdmin",
//     meta: {
//       title: "Tableau de bord MK-CO",
//     },
//     component: () => import("../views/admin/Dashboard.vue"),
//   },
//   {
//     path: "/:pathMatch(.*)",
//     name: "NotFound",
//     component: () => import("../views/visiteur/NotFound.vue"),
//     meta: {
//       title: "Page introuvable",
//     },
//   },
// ];

const router = new VueRouter({
  base: process.env.BASE_URL,
  //history: true,
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (
      from.name == "Accueil" &&
      to.name == "ServiceVisiteur" &&
      window.innerWidth < 991
    ) {
      return { x: 0, y: 460 };
    } else if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((from) => {
  document.title = from.meta.title;
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.noAccessTo)) {
    if (!store.getters["visiteurStore/isLoggedIn"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.noAccesOnLogged)) {
    if (!store.getters["collaborateurStore/isLoggedInCollaborateur"]) {
      next();
      return;
    }
    next("/admin/dashboard");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.noAccessNotLoggedIn)) {
    if (store.getters["collaborateurStore/isLoggedInCollaborateur"]) {
      next();
      return;
    }
    next("/admin/se-connecter-admin");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.needSuperAdmin)) {
    if (store.getters["collaborateurStore/meCollab"].isSuperAdmin) {
      next();
      return;
    }
    next("/admin/dashboard");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.noAccessNotLoggedInVisiteur)) {
    if (store.getters["visiteurStore/isLoggedIn"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
