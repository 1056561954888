import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "jquery";
import "bootstrap";
import moment from "moment";
import VueScrollReveal from "vue-scroll-reveal";
import Vue2Editor from "vue2-editor";
import infiniteScroll from "vue-infinite-scroll";
import VueApexCharts from "vue-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate";
import "sweetalert2/dist/sweetalert2.min.css";

const optionsSweetalert = {
  confirmButtonColor: "#ffcd03",
  confirmButtonText: "Fermer",
  allowEnterKey: false,
};

Vue.use(VueSweetalert2, optionsSweetalert);
Vue.use(Vuelidate);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(infiniteScroll);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(VueScrollReveal, {
  duration: 1000,
  scale: 1,
});
Vue.use(Vue2Editor);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
