<template>
  <div id="app">
    <router-view :key="$route.path" />
    <button
      data-bs-toggle="modal"
      label="cookie"
      data-bs-target="#modalCookie"
      class="cursor-pointer"
      hidden
      ref="cookie"
    />
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="modalCookie"
      tabindex="-1"
      aria-labelledby="modalLabelCookie"
      aria-hidden="true"
    >
      <ModalCookie />
    </div>
  </div>
</template>

<script>
import "moment/locale/fr";
import moment from "moment";
import { socket } from "../src/api/socket";
//import { decodeToken } from "../src/utils/decodeToken";
import { Tooltip } from "bootstrap";
import ModalCookie from "../src/components/visiteur/ModalCookie.vue";

new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "qlq sec",
    ss: "%d sec",
    m: "1 min",
    mm: "%d min",
    h: "1 h",
    hh: "%d h",
    d: "1 j",
    dd: "%d j",
    w: "1 sem",
    ww: "%d sem",
    M: "1 mois",
    MM: "%d mois",
    y: "1 an",
    yy: "%d ans",
  },
});

export default {
  components: { ModalCookie },
  mounted: function () {
    socket.emit("connection");
    socket.emit("test", {
      data: "hello",
    });
    socket.on("arrival-test", () => {
      //console.log("arrival-test");
    });

    // socket.on("alert-comment", (data) => {
    //   try {
    //     const decode = decodeToken(localStorage.getItem("tokenCollab"));
    //     if (decode) {
    //       alert(JSON.stringify(data));
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // });
    //this.$refs.cookie.click();
  },
};
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.date-notification {
  min-width: 45px;
  text-align: end;
}
@font-face {
  font-family: "Aquire";
  src: url("./assets/fonts/Aquire.otf");
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins.ttf");
}

@import "assets/scss/custom-bootstrap.scss";
</style>
